import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import CreateChecklistTemplateModal from 'src/components/checklist/checklist-template/CreateChecklistTemplateModal'
import { useChecklistTemplateColumns } from 'src/components/checklist/checklist-template/useChecklistTemplateColumns'
import { ChecklistTemplateDomains } from 'src/components/checklist/utils'
import useBoolean from 'src/hooks/useBoolean'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import {
  useFilteredChecklistTemplates,
  useMutateChecklistTemplate,
} from 'src/query/checklist'
import { IChecklistTemplate } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface IChecklistProps {
  domain: ChecklistTemplateDomains
  domainFilter?: string
}
const ChecklistTemplateTable = ({ domain, domainFilter }: IChecklistProps) => {
  const { toggle, value } = useBoolean(['new'])
  const { deleteList, bulkDeleteLists } = useMutateChecklistTemplate({})

  const { t } = useTranslation()
  const { columns, defaultOrdering } = useChecklistTemplateColumns()
  const { confirmDelete } = useDeleteModal()
  const { isProjectAdmin } = useUserAccess()
  const history = useHistory()
  const location = useLocation()
  const { path: basePath } = useRouteMatch()

  const onDeleteClick = async (checklist: IChecklistTemplate) => {
    const confirm = await confirmDelete({
      itemIdnType: `${checklist.name} (${t('checklist_template')})`,
      itemName: `${checklist?.name}`,
    })
    if (confirm) {
      deleteList.mutate(checklist.id)
    }
  }

  const onRowClick = (row: IChecklistTemplate) => {
    history.push(generateRedirectUrl(row))
  }

  const generateRedirectUrl = (row: IChecklistTemplate) => {
    if (domainFilter) {
      return `${location.pathname}${row.id}`
    } else {
      return `${basePath}/checklists_templates/${row.domain}/${row.id}`
    }
  }

  return (
    <div>
      <Table
        generateRedirectUrl={generateRedirectUrl}
        initialFilter={domainFilter ? { domain: [domainFilter] } : {}}
        tableButtons={(selectedItems) => ({
          onBulkDelete: isProjectAdmin
            ? () => bulkDeleteLists.mutate(selectedItems.map((list) => list.id))
            : undefined,
          customButtons: isProjectAdmin
            ? [
                <Button
                  key="new"
                  className={'h-8'}
                  type={ButtonType.PRIMARY}
                  onClick={() => toggle('new')}
                >
                  {t('new_checklist_template')}
                </Button>,
              ]
            : [],
        })}
        defaultOrdering={defaultOrdering}
        name={
          domainFilter ? `checklistTable-${domainFilter}` : 'checklistTable'
        }
        modelName={DataModel.CHECKLIST}
        columns={columns}
        useDataQuery={useFilteredChecklistTemplates}
        onDeleteClick={isProjectAdmin ? onDeleteClick : undefined}
        onRowClick={onRowClick}
        selectable={isProjectAdmin}
      />
      {value('new') && (
        <CreateChecklistTemplateModal
          onCloseModal={() => toggle('new')}
          show={value('new')}
          domain={domain}
        />
      )}
    </div>
  )
}

export default ChecklistTemplateTable
